<script setup lang="ts">
import { useCartStore } from '~/stores/cart.ts';
import { useRoute } from 'vue-router';
import { useLoganStore } from '../stores/logan';
import { v4 as uuidv4 } from 'uuid';
import { getMoengageUserId } from '~/utils/helpers';
import { revenueTracking } from '~/utils/revenue-tracking';
import Mobile from '~/components/navigation/mobile/index.vue';
import Desktop from '~/components/navigation/desktop/index.vue';
import { useDeviceType } from '~/utils/use-device-type';
import { useCountryCode } from '~/stores/country';
import { Toaster } from '@laam/ui/base';
import type { ShippingConfig, ShippingDetails } from '~/types/statsig';
import SuccessVerificationPopover from '~/components/campus-program/SuccessVerificationPopover.vue';
import type { BannerReasons } from '~/components/TopBanner.vue';

const loganStore = useLoganStore();

const { addOns } = useGlobalStore();

const countryCode = useCountryCode().countryCode;
const shippingConfig = ref<ShippingConfig | null>(null);

const showNov11Sale = computed(() => {
	if (addOns?.['11-11-sale']?.enabled) {
		return true;
	} else return false;
});

provide('shippingConfig', shippingConfig);

const route = useRoute();

const CartStore = useCartStore();

const mobileView = ref(isMobileView());
const desktopView = ref(!isMobileView());
const { deviceType } = useDeviceType();
const showCampusVerificationPopover = ref(false);
const topBannerReason = ref<BannerReasons>();

onMounted(() => {
	mobileView.value = isMobileView();
	desktopView.value = !isMobileView();

	if (route.query.app) {
		localStorage.setItem('isApp', 'true');
		if (loganStore.isSignedIn === false) {
			const showWelcomeScreen = localStorage.getItem('showWelcomeScreen');
			if (showWelcomeScreen === null || showWelcomeScreen === 'true') {
				navigateTo('/onboarding');
			}
		}
		const preferences = localStorage.getItem('preferences');
		if (preferences) {
			if (preferences.includes('Women')) {
				navigateTo('/');
			} else if (preferences.includes('Men')) {
				navigateTo('/men');
			} else if (preferences.includes('Kids')) {
				navigateTo('/kids');
			}
		}
	}
	revenueTracking();
	if (
		localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID')?.length === 0 &&
		localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID') === ''
	) {
		localStorage.setItem('STATSIG_LOCAL_STORAGE_STABLE_ID', uuidv4());
	}
	if (window.flutter_inappwebview) {
		const moengageUserId = getMoengageUserId();
		if (moengageUserId) {
			window.flutter_inappwebview.callHandler(
				'moengageUserUpdate',
				moengageUserId,
			);
		}
	}
});
watch(
	() => isMobileView(),
	(isMobile) => {
		mobileView.value = isMobile;
		desktopView.value = !isMobile;
	},
);
const getFreeShippingConfig = () => {
	const statsigConfig = window.$statsig?.getConfig(
		'free_shipping_feature',
	) as unknown as Ref<ShippingDetails>;
	if (!statsigConfig?.value.countries) {
		throw new Error('free_shipping_feature config is not defined');
	}
	shippingConfig.value =
		statsigConfig.value.countries[countryCode ?? 'PK'] ?? null;
	if (shippingConfig.value) {
		topBannerReason.value = 'free-shipping';
	}
};
const updateTopBannerReason = (reasons: Array<boolean>) => {
	const [isCampusDocVerificationRequested, isCampusEmailVerificationRequested] =
		reasons;
	if (isCampusDocVerificationRequested) {
		topBannerReason.value = 'campus-doc-verification';
	} else if (isCampusEmailVerificationRequested) {
		topBannerReason.value = 'campus-email-verification';
	}
};

onMounted(async () => {
	if (CartStore.cartId === '') {
		await CartStore.createCart();
	} else {
		await CartStore.getCartById();
	}

	if (route.query.campus_verified) {
		showCampusVerificationPopover.value = true;
	}
	callWithRetries(() => getFreeShippingConfig());
	updateTopBannerReason([
		loganStore.isCampusDocVerificationRequested,
		loganStore.isCampusEmailVerificationRequested,
	]);
});

watch(
	[
		() => loganStore.isCampusDocVerificationRequested,
		() => loganStore.isCampusEmailVerificationRequested,
	],
	updateTopBannerReason,
);

provide('deviceType', deviceType);
provide('topBannerReason', topBannerReason);
provide('showNov11Sale', showNov11Sale);

const isDefaultLayoutDisabled = computed(
	() =>
		route.path.includes('/checkout') ||
		route.path.includes('/campus-program') ||
		route.path.includes('/onboarding'),
);
</script>

<template>
	<div v-if="!isDefaultLayoutDisabled">
		<SuccessVerificationPopover v-if="showCampusVerificationPopover" />
		<Toaster :show-close="false"></Toaster>
		<Mobile v-if="deviceType === 'mobile'">
			<slot />
		</Mobile>

		<Desktop v-else>
			<slot />
		</Desktop>
	</div>
	<div v-else>
		<slot />
	</div>
</template>

<script setup lang="ts">
import {
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTrigger,
} from 'radix-vue';
import Brands from '../mobile/Brands.vue';
import type { BannerReasons } from '~/components/TopBanner.vue';
const open = ref(false);
const updateOpen = () => {
	open.value = !open.value;
};

const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;
</script>

<template>
	<DialogRoot v-model:open="open" @update:open="open != open">
		<DialogTrigger
			class="nav-button hover:bg-gray-50 hover:cursor-pointer"
			as-child
			@click="open = true"
		>
			<span class="nav-button-text">Brands</span>
		</DialogTrigger>

		<DialogPortal>
			<DialogOverlay
				class="bg-gray-800/60 data-[state=open]:animate-overlay-show fixed top-[73px] right-0 left-[250px] bottom-0 z-30"
				:style="
					topBannerReason
						? { top: `calc(var(--laam-shipping-banner-height) + 73px)` }
						: {}
				"
			/>

			<DialogContent
				class="data-[state=open]:animate-content-show fixed bg-white focus:outline-none h-full left-[250px] top-[73px] z-50 grid w-full max-w-lg -translate-x-0 -translate-y-0 gap-3xl border-r !rounded-none duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out !border-none data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
				:style="
					topBannerReason
						? { top: `calc(var(--laam-shipping-banner-height) + 73px)` }
						: {}
				"
			>
				<Brands @update-open="updateOpen" />
			</DialogContent>
		</DialogPortal>
	</DialogRoot>
</template>

<style>
.nav-button {
	@apply py-xl px-xl w-full gap-xxs border-0 flex items-center justify-between;
}

.nav-button-text {
	@apply lg:!text-md font-medium !text-xxs lg:text-gray-800 capitalize;
}
</style>
